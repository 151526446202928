import Button from 'components/shared/button';
import validateRequired from './validate-required';
import withPropsValidation from './with-props-validation.tsx';

const Section = ({
  data: {
    title,
    backgroundImage,
    isWhiteColor,
    buttonsCollection: { items: buttonItems = [] } = { items: [] },
  },
}) => {
  const backgroundImageUrl = backgroundImage ? `url('${backgroundImage.url}')` : '';
  const baseColor = isWhiteColor ? 'white' : 'blue';

  const buttons = buttonItems.filter(btn => btn?.link);

  return (
    <div
      className={`items-center justify-between bg-[#041C2C33] bg-cover bg-center bg-no-repeat px-[39px] py-[65px] text-center text-${baseColor} antialiased md:flex md:py-8 md:pl-14 md:pr-[88px]`}
      style={{ backgroundImage: backgroundImageUrl }}
    >
      <h2 className="font-nimbus-sans-extd-d text-[35px] font-bold uppercase leading-[31.5px] -tracking-[0.02em]">
        {title}
      </h2>
      <div className="mt-[15px] flex w-full flex-col flex-wrap items-center justify-center gap-2 md:mt-0 md:w-fit md:flex-row">
        {buttons.filter(Boolean).map(({ link, style }) => (
          <Button link={link} type={style} className="w-fit min-w-[168px]" />
        ))}
      </div>
    </div>
  );
};

export default withPropsValidation(Section, ({ data: { name } }) =>
  validateRequired({ Name: name })
);
